import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useDialogProps } from 'component/hooks/useDialogProps';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Close from 'component/new_design/icons/Close.svg?react';
import { Step, StepStatus } from '../Step';

type Step = {
  readonly title: string;
  readonly description: string;
  readonly index: number;
};

export default function WizardDialog({
  activeStep,
  onClose,
  name,
  title,
  steps,
  stepNode,
}: {
  readonly activeStep: number;
  readonly onClose: () => void;
  readonly name: string;
  readonly title: ReactNode;
  readonly steps: Step[];
  readonly stepNode: ReactNode;
}) {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const sdkDialogProps = useDialogProps();
  const stepsRef = useRef<HTMLDivElement | null>(null);
  const [stepsHeight, setStepsHeight] = useState<number>(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        // 16px is the padding of the steps container
        setStepsHeight(entry.contentRect.height + 20);
      }
    });

    if (stepsRef.current) {
      resizeObserver.observe(stepsRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      id={`${name}-modal`}
      fullScreen={isSmallBreakpoint}
      open
      onClose={onClose}
      aria-labelledby={`${name}-title`}
      aria-describedby={`${name}-description`}
      {...sdkDialogProps}
    >
      <DialogTitle id={`${name}-title`}>
        {title}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 !important' }}>
        <Box
          sx={theme => ({
            position: 'relative',
            height: '60vh',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
              height: 'auto',
            },
          })}
        >
          <Box
            ref={stepsRef}
            sx={theme => ({
              position: 'absolute',
              width: '320px',
              left: 0,
              height: '60vh',
              padding: 4,
              overflowY: 'auto',
              borderRight: `1px solid ${theme.palette.greys[50]}`,
              [theme.breakpoints.down('md')]: {
                position: 'relative',
                width: '100%',
                borderRight: `0px solid`,
                height: 'fit-content',
                borderBottom: `1px solid ${theme.palette.greys[50]}`,
              },
            })}
          >
            <Stack gap={4} direction={{ xs: 'row', sm: 'row', md: 'column' }}>
              {steps.map(step => {
                let status: StepStatus = 'default';
                if (step.index < activeStep) {
                  status = 'success';
                } else if (step.index === activeStep) {
                  status = 'active';
                }

                return (
                  <Step
                    key={step.index}
                    status={status}
                    number={step.index}
                    title={step.title}
                    description={step.description}
                  />
                );
              })}
            </Stack>
          </Box>
          <Box
            sx={theme => ({
              position: 'absolute',
              width: `calc(100% - 320px)`,
              right: 0,
              height: '60vh',
              overflowY: 'auto',
              borderBottom: `1px solid ${theme.palette.greys[50]}`,
              [theme.breakpoints.down('md')]: {
                height: `calc(100% - ${stepsHeight}px)`,
                position: 'relative',
                width: '100%',
                borderBottom: `0px solid`,
              },
            })}
          >
            {stepNode}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
