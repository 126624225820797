import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button, IconButton, Tooltip } from '@mui/material';
import Copy from '../icons/Copy.svg?react';
import { useTranslation } from 'react-i18next';

export const CopyButton = ({
  value,
  hideText,
}: {
  readonly value: string;
  readonly hideText?: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();
  const onCopy = () => copy(value);

  return (
    <Tooltip
      title="Copied!"
      placement="top"
      open={show}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      {!hideText ? (
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={() => {
            onCopy();
            setShow(true);

            setTimeout(() => {
              setShow(false);
            }, 1000);
          }}
          startIcon={<Copy />}
        >
          {t('copy')}
        </Button>
      ) : (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            onCopy();
            setShow(true);

            setTimeout(() => {
              setShow(false);
            }, 1000);
          }}
        >
          <Copy />
        </IconButton>
      )}
    </Tooltip>
  );
};
